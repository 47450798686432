/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useReducer, useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { actions, initialState, PAYROLL_TYPE, payrollSimulatorReducer } from './PayrollSimulator.reducer'

import { getCookie, getParameterByName } from 'src/shared/helpers'

import * as URLs from 'src/config/api/Urls'

import { PayrollSimulatorProps, StateProps } from '../types'
import { BoxSimulador } from './style'

function currencyToFloat (source: string, currencyNumber: string) {
  const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return source === 'range' ? result * 100 : result
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const PayrollSimulatorNew = ({ page, borderColor, sectionNumber, sectionName }: PayrollSimulatorProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ state, dispatch ] = useReducer(payrollSimulatorReducer, initialState(page))
  const { simulationType, inputValue, resultValue, qtdParcel, percentage, maxValue }: StateProps = state
  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ gaid, setGaid ] = useState<string>()
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utmTerm, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ codigoIndiqueGanhe, setCodigoIndiqueGanhe ] = useState<string | string[] | null | undefined>(null)
  const familyType = `${page}`
  let nextStepValidation

  const inputMinValue = PAYROLL_TYPE[page].minValue
  const inputMaxValue = PAYROLL_TYPE[page].maxValue

  if (simulationType === 'total') {
    nextStepValidation = inputValue < inputMinValue
  } else {
    nextStepValidation = resultValue < inputMinValue
  }

  function changeInput (evt: { target: { name: string; value: string } }) {
    const inputName = evt.target.name
    let inputValue = evt.target.value

    if (simulationType === 'total') {
      if (currencyToFloat(inputName, inputValue) > inputMaxValue) {
        inputValue = `${inputMaxValue}00`
      }
    }

    const insertValue = currencyToFloat(inputName, inputValue)
    dispatch(actions.setInputValue(insertValue))
  }

  function setSimulationType (simulationType) {
    dispatch(actions.setSimulationType(simulationType))
  }

  function changeParcel (qtdParcel) {
    dispatch(actions.setQtdParcel(qtdParcel))
  }

  useEffect(() => {
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setGaid(getCookie('_ga'))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
    setCodigoIndiqueGanhe(getParameterByName('codigoIndiqueGanhe', ''))
  }, [])

  function handleButtonClick () {
    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utmSource ? `&utmSource=${utmSource}` : ''
    const parametersUtmMedium = utmMedium ? `&utmSMedium=${utmMedium}` : ''
    const parametersUtmCampaign = utmCampaign ? `&utmSCampaign=${utmCampaign}` : ''
    const parametersUtmContent = utmContent ? `&utmContent=${utmContent}` : ''
    const parametersUtmTerm = utmTerm ? `&utmTerm=${utmTerm}` : ''
    const parametersUtmCodigo = codigoIndiqueGanhe ? `&codigoIndiqueGanhe=${codigoIndiqueGanhe}` : ''

    const urlTotal = `${URLs.API_CONSIGNADO_PRIVADO_PATH}?tipoSimulacao=1&valorTotal=${inputValue.toFixed(2)}&valorParcela=${resultValue.toFixed(2)}&qtdParcelas=${qtdParcel}&familia=${familyType}&origem=site${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}${parametersUtmCodigo}`
    const urlParcel = `${URLs.API_CONSIGNADO_PRIVADO_PATH}?tipoSimulacao=2&valorTotal=${resultValue.toFixed(2)}&valorParcela=${inputValue.toFixed(2)}&qtdParcelas=${qtdParcel}&familia=${familyType}&origem=site${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}${parametersUtmCodigo}`

    if (typeof window !== 'undefined') {
      if (simulationType === 'total') {
        window.location.href = urlTotal
      } else {
        window.location.href = urlParcel
      }
    }

    sendDatalayerEvent({
      section: sectionNumber,
      section_name: sectionName,
      element_action: 'click button',
      element_name: 'Simular meu empréstimo',
      redirect_url: simulationType === 'total' ? urlTotal : urlParcel,
      step: 'start',
    })
  }

  return (
    <BoxSimulador borderColor={borderColor} page={page} sectionNumber={sectionNumber}>
      <div className='options mx-auto'>
        <div className='row'>
          <div className='col-12 mx-auto'>
            <ul className='select'>
              <li className={`${simulationType === 'total' ? 'active' : ''}`} onClick={() => { setSimulationType('total') }}><span>Valor Total</span></li>
              <li className={`${simulationType === 'parcel' ? 'active' : ''}`} onClick={() => { setSimulationType('parcel') }}><span>Parcela/mês</span></li>
            </ul>
          </div>
          <div className='col-12 mx-auto text-center'>
            { simulationType === 'total'
              ? <p className='fs-12 lh-17 fs-xl-14 lh-xl-16 text-grayscale--400 fw-400'>De quanto você precisa?</p>
              : <p className='fs-12 lh-17 fs-xl-14 lh-xl-16 text-grayscale--400 fw-400'>Quanto gostaria de pagar de parcela?</p>
            }
            <div className='d-flex line-bottom mb-2'>
              <span className='cifra mr-2'>R$</span>
              <input
                type='text'
                name='valor'
                value={floatToCurrency(inputValue)}
                onChange={changeInput}
                className='border-0 result-simulator'
              />
            </div>
            <span className='range-slider'>
              <input
                type='range'
                name='range'
                tabIndex={-1}
                min={1600}
                max={maxValue}
                value={inputValue}
                onChange={changeInput}
              />
              <span className='slider-container'>
                <span className='bar'>
                  <span style={{ width: `${percentage}%` }} />
                </span>
                <span className='bar-btn' style={{ left: `${percentage}%` }} />
              </span>
            </span>
          </div>

          <div className='col-12 col-lg-11 mx-auto text-center justify-content-center mt-lg-1 px-0'>
            <p className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400 mb-2 d-block'>Em quantas parcelas?</p>
            <ul className='mt-1 mb-2 parcels mt-2 mx-auto'>
              {
                PAYROLL_TYPE[page].parcels.map((parcel: number) => (
                  <li key={parcel}>
                    <button
                      onClick={() => { changeParcel(parcel) }}
                      className={`${qtdParcel === parcel ? 'active' : ''}`}
                    >{parcel}
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className='col-12 text-center mt-lg-2'>
            { simulationType === 'total'
            ? <span className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400'>Valor aproximado da parcela/mês:</span>
            : <span className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400'>Valor aproximado do empréstimo:</span>}
            <span className='d-block fs-24 lh-30 fw-400 text-orange--base mb-0 py-3 pt-md-0 pb-md-2 font-sora'>
              <span className='text-grayscale--300'>R$</span> {floatToCurrency(resultValue)}
            </span>
            <span className='rate d-block text-grayscale--400 mb-xl-2'>Os valores podem variar de acordo com o convênio</span>
          </div>
          <div className='col-12 mx-auto justify-content-center mt-3 text-center'>
            {
              nextStepValidation
              ? (
                <button
                  disabled
                  onClick={handleButtonClick}
                  className='buttonSend fw-700'
                >
                  Simular meu empréstimo
                </button>
              )
              : (
                <button
                  onClick={handleButtonClick}
                  className='buttonSend fw-700'
                >
                  Simular meu empréstimo
                </button>
              )
            }
          </div>
        </div>
      </div>
    </BoxSimulador>
  )
}

export default PayrollSimulatorNew
